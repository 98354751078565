import styles from "./MemberTrainingInfo.module.css";
import { useAuth0 } from "@auth0/auth0-react";
import { useMemberStore } from "../../store/memberStore";
import { Empty } from "antd";
import { useParams } from "react-router-dom";
import { EditFilled } from "@ant-design/icons";
import MemberAccountInfoEditModal from "../MemberAccountInfoEditModal";
import { useState } from "react";

const MemberTrainingInfo = () => {
  let { memberUserId } = useParams();
  const { isAuthenticated, user } = useAuth0();

  const memberAccountInfo = useMemberStore((state) => state.memberAccountInfo);

  const [editAccountInfoModalState, setEditAccountInfoModalState] =
    useState(false);

  const changeEditAccountInfoModalState = () => {
    setEditAccountInfoModalState(!editAccountInfoModalState);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.aboutMeSection}>
        <div className={styles.aboutMeSectionHeadingRow}>
          <div className={styles.aboutMeSectionHeading}>About me</div>
          {isAuthenticated &&
          memberAccountInfo.username === memberUserId &&
          memberAccountInfo?.user_authId === user?.sub ? (
            <div  className={styles.aboutMeSectionHeadingRowButtons}>
              {/* {memberAccountInfo?.role === "basic" ? (
                <div
                  className={styles.editProfileButton}
                  onClick={() => navigate("/member-subscription")}
                >
                  <div className={styles.editProfileButtonText}>
                    Upgrade Membership
                  </div>
                </div>
              ) : (
                ""
              )} */}
              <div
                className={styles.editProfileButton}
                onClick={changeEditAccountInfoModalState}
              >
                <EditFilled className={styles.editProfileButtonIcon} />
                <div className={styles.editProfileButtonText}>Edit Profile</div>
              </div>
            </div>
          ) : null}
        </div>
        <div className={styles.aboutMeSectionContent}>
          {memberAccountInfo?.aboutme ? memberAccountInfo?.aboutme : <Empty />}
        </div>
      </div>

      <div className={styles.trainingAndCredentialsSection}>
        <div className={styles.trainingAndCredentialsSectionHeading}>
          Training & Credentials
        </div>
        <div className={styles.trainingAndCredentialsSectionContent}>
          {memberAccountInfo?.qualifications &&
          memberAccountInfo?.qualifications?.length ? (
            memberAccountInfo?.qualifications
              ?.filter((i) => i)
              ?.map((i) => <p key={i?.id}>{i?.trainingandcredentials}</p>)
          ) : (
            <Empty />
          )}
        </div>
      </div>
      {editAccountInfoModalState && (
        <MemberAccountInfoEditModal
          isOpen={editAccountInfoModalState}
          onClose={changeEditAccountInfoModalState}
          editData={memberAccountInfo}
        />
      )}
    </div>
  );
};

export default MemberTrainingInfo;
