import { Auth0Provider } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom"; // Use useNavigate instead of useHistory
import "./App.css";
import Router from "./router";

function App() {
  const navigate = useNavigate(); // useNavigate replaces useHistory

  // Function to handle redirection after login
  const onRedirectCallback = (appState) => {
    // Redirect to the path the user was originally going to or fallback to the home page
    navigate(appState?.returnTo || "/auth0Callback");
  };

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENTID}
      authorizationParams={{
        redirect_uri: process.env.REACT_APP_AUTH0_REDIRECT_URL,
      }}
      useRefreshTokens={true}
      cacheLocation="localstorage"
      onRedirectCallback={onRedirectCallback}  // <-- Add this to handle redirects
    >
      <div className="App">
        <Router />
      </div>
    </Auth0Provider>
  );
}

export default App;