import React from "react";
import styles from "./MemberContent.module.css";
import { Collapse, Divider, Empty, message } from "antd";
import MemberContentAddEditModal from "../MemberContentAddEditModal";
import { Fragment, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useMemberStore } from "../../store/memberStore";
import {
  deleteMemberContentAPI,
  getMemberContentsAPI,
} from "../../api/memberContentsApis";
import DeleteConformationModal from "../DeleteConformationModal";
import { useAuth0 } from "@auth0/auth0-react";

import { PlusCircleFilled, EditFilled, DeleteFilled } from "@ant-design/icons";

const { Panel } = Collapse;

const MemberContent = () => {
  let { memberUserId } = useParams();
  const { isAuthenticated, getIdTokenClaims, user } = useAuth0();

  const memberAccountInfo = useMemberStore((state) => state.memberAccountInfo);

  const [contentAddEditModalState, setContentAddEditModalState] =
    useState(false);
  const [contentDeleteModalState, setContentDeleteModalState] = useState(false);
  const [contents, setContents] = useState([]);
  const [selectedContentForEditOrDelete, setSelectedContentForEditOrDelete] =
    useState();

  const changeContentDeleteModalState = () => {
    setContentDeleteModalState(!contentDeleteModalState);
  };

  const changeContentAddEditModalState = () => {
    setContentAddEditModalState(!contentAddEditModalState);
  };

  const deleteMemberContentsHelper = async () => {
    try {
      const token = await getIdTokenClaims();
      const response = await deleteMemberContentAPI(
        selectedContentForEditOrDelete.id,
        token.__raw
      );

      console.log("response :>> ", response);

      changeContentDeleteModalState();
      getMemberContentsHelper();
      setSelectedContentForEditOrDelete();

      message.info("Content Deleted Successfully");
    } catch (error) {
      console.log(error);
      message.error("Something went wrong, Unable to delete the content");
    }
  };

  const getMemberContentsHelper = useCallback(async () => {
    try {
      const token = await getIdTokenClaims();
      const response = await getMemberContentsAPI(memberUserId, token.__raw);

      console.log("response :>> ", response);

      setContents(response.data.data);
    } catch (error) {
      console.log(error);
    }
  }, [memberUserId, getIdTokenClaims]);

  useEffect(() => {
    getMemberContentsHelper();
  }, [getMemberContentsHelper]);

  return (
    <div className={styles.wrapper}>
      <Collapse
        style={{ width: "100%", margin: "24px 0" }}
        expandIconPosition={"end"}
        size="large"
      >
        <Panel header="Content Section" key="1">
          <div className={styles.contentHeadingSection}>
            <div className={styles.contentHeadingText}>Content</div>
            {isAuthenticated &&
            memberAccountInfo.username === memberUserId &&
            memberAccountInfo?.user_authId === user?.sub ? (
              <div
                className={styles.contentHeadingAddContentButton}
                onClick={changeContentAddEditModalState}
              >
                <PlusCircleFilled
                  className={styles.contentHeadingAddContentButtonIcon}
                />
                <p className={styles.contentHeadingAddContentButtonText}>
                  Add Content
                </p>
              </div>
            ) : null}
          </div>
          <div className={styles.contentList}>
            {contents?.length ? (
              contents
                ?.filter((i) => i)
                ?.map((i) => (
                  <Fragment key={i.id}>
                    <div className={styles.contentListItem} key={i.id}>
                      {isAuthenticated &&
                      memberAccountInfo.username === memberUserId &&
                      memberAccountInfo?.user_authId === user?.sub ? (
                        <div
                          className={styles.contentListItemEditButton}
                          onClick={() => {
                            changeContentAddEditModalState();
                            setSelectedContentForEditOrDelete(i);
                          }}
                        >
                          <EditFilled />
                        </div>
                      ) : null}
                      {isAuthenticated &&
                      memberAccountInfo.username === memberUserId &&
                      memberAccountInfo?.user_authId === user?.sub ? (
                        <div
                          className={styles.contentListItemDeleteButton}
                          onClick={() => {
                            setSelectedContentForEditOrDelete(i);
                            changeContentDeleteModalState();
                          }}
                        >
                          <DeleteFilled />
                        </div>
                      ) : null}

                      <div className={styles.contentListItemInfoSection}>
                        <div
                          className={styles.contentListItemInfoSectionTopLine}
                        >
                          <p className={styles.contentListItemName}>
                            {i?.title}
                          </p>
                        </div>
                        <div className={styles.contentListItemPrice}>
                          <b>Content Type:</b> &nbsp; &nbsp;
                          {i.externalContentType} <br />
                          (Please go to the mobile app to view the content)
                        </div>
                        <div className={styles.contentListItemPrice}>
                          <b>Privacy:</b> &nbsp; &nbsp;
                          {i.privacy}
                        </div>
                        <div
                          className={
                            styles.contentListItemInfoSectionDescription
                          }
                        >
                          {i.description}
                        </div>
                        <div
                          className={
                            styles.contentListItemInfoSectionWellnessKeywords
                          }
                        >
                          {i.wellnessKeywords.length
                            ? i.wellnessKeywords
                                ?.filter((i) => i)
                                .map((i) => (
                                  <div
                                    key={i.id}
                                    className={
                                      styles.contentListItemInfoSectionWellnessKeywordItem
                                    }
                                  >
                                    {i?.name}
                                  </div>
                                ))
                            : null}
                        </div>
                      </div>
                    </div>
                    <Divider />
                  </Fragment>
                ))
            ) : (
              <Empty style={{ marginTop: "100px" }} />
            )}
          </div>
        </Panel>
      </Collapse>
      {contentAddEditModalState && (
        <MemberContentAddEditModal
          isOpen={contentAddEditModalState}
          editData={selectedContentForEditOrDelete}
          onClose={() => {
            changeContentAddEditModalState();
            setSelectedContentForEditOrDelete();
            getMemberContentsHelper();
          }}
        />
      )}
      {contentDeleteModalState && (
        <DeleteConformationModal
          title={"Delete content"}
          isOpen={contentDeleteModalState}
          onClose={() => {
            changeContentDeleteModalState();
            setSelectedContentForEditOrDelete();
            getMemberContentsHelper();
          }}
          content={
            "Are you sure you want to delete this content. once deleted it can not be recovered"
          }
          onConform={deleteMemberContentsHelper}
        />
      )}
    </div>
  );
};

export default MemberContent;
