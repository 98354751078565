import styles from "./MemberEventsInfo.module.css";
import { Collapse, Divider, Empty, message, Spin } from "antd";
import MemberEventAddEditForm from "../MemberEventAddEditForm";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import {
  deleteMemberEventAPI,
  getMemberEventsAPI,
  getPreviousMemberEventsAPI,
  getRelationshipMetadataByUserAPI,
  registerEventAPI,
} from "../../api/memberEventsApis";
import { useParams } from "react-router-dom";
import { useMemberStore } from "../../store/memberStore";
import dayjs from "dayjs";
import DeleteConformationModal from "../DeleteConformationModal";
import { useAuth0 } from "@auth0/auth0-react";
import { imageIsNotInS3, openLinkInNewTab } from "../../utils";
import {
  PlusCircleFilled,
  EditFilled,
  DeleteFilled,
  LinkOutlined,
  EnvironmentOutlined,
} from "@ant-design/icons";
import currencyData from "../../constants/currency.json";

var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

const { Panel } = Collapse;

const MemberEventsInfo = () => {
  let { memberUserId } = useParams();
  const { getIdTokenClaims, isAuthenticated, user, loginWithRedirect } =
    useAuth0();

  const memberAccountInfo = useMemberStore((state) => state.memberAccountInfo);

  const [eventAddEditModalState, setEventAddEditModalState] = useState(false);
  const [eventDeleteModalState, setEventDeleteModalState] = useState(false);
  const [loader, setLoader] = useState(false);
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [previousEvents, setPreviousEvents] = useState([]);
  const [eventInterestData, setEventInterestData] = useState([]);
  const [selectedEventForEditOrDelete, setSelectedEventForEditOrDelete] =
    useState();

  const changeEventAddEditModalState = () => {
    setEventAddEditModalState(!eventAddEditModalState);
  };

  const changeEventDeleteModalState = () => {
    setEventDeleteModalState(!eventDeleteModalState);
  };

  const deleteMemberEventHelper = async () => {
    try {
      const token = await getIdTokenClaims();
      await deleteMemberEventAPI(selectedEventForEditOrDelete.id, token.__raw);

      changeEventDeleteModalState();
      getMemberEventsHelper();
      setSelectedEventForEditOrDelete();
      message.info("Event Deleted Successfully");
    } catch (error) {
      console.log(error);
      message.error("Something went wrong, Unable to delete the Event");
    }
  };

  const getMemberEventsHelper = useCallback(async () => {
    try {
      const upcomingEventResponse = await getMemberEventsAPI(memberUserId);
      setUpcomingEvents(upcomingEventResponse.data.data);

      if (
        isAuthenticated &&
        memberAccountInfo.username === memberUserId &&
        memberAccountInfo?.user_authId === user?.sub
      ) {
        const previousEventResponse = await getPreviousMemberEventsAPI(
          memberUserId
        );

        setPreviousEvents(previousEventResponse.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  }, [memberUserId, isAuthenticated, memberAccountInfo, user]);

  const registerEventHandler = async (event) => {
    setLoader(true);
    try {
      const token = await getIdTokenClaims();
      await registerEventAPI(event.id, token.__raw);

      getRelationshipMetadataByUserHandler();
      setLoader(false);

      if (
        eventInterestData?.length &&
        eventInterestData?.find(
          (j) => Number(j.interest_type_id) === Number(event.id)
        )
      ) {
        //
      } else if (event?.link) {
        openLinkInNewTab(event?.link)
      }
    } catch (error) {
      console.log("error :>> ", error);
      setLoader(false);
    }
  };

  const getRelationshipMetadataByUserHandler = async () => {
    setLoader(true);
    try {
      const token = await getIdTokenClaims();
      const response = await getRelationshipMetadataByUserAPI(token.__raw);
      setEventInterestData(response.data);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.log("error :>> ", error);
    }
  };

  useEffect(() => {
    getMemberEventsHelper();

    if (
      isAuthenticated &&
      memberAccountInfo.username === memberUserId &&
      memberAccountInfo?.user_authId === user?.sub
    ) {
      //
    } else if (
      isAuthenticated &&
      memberAccountInfo?.user_authId !== user?.sub
    ) {
      getRelationshipMetadataByUserHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.wrapper}>
      <Collapse
        style={{ width: "100%", margin: "24px 0" }}
        expandIconPosition={"end"}
        size="large"
      >
        <Panel header="Events and Classes" key="1">
          <Spin spinning={loader}>
            <div className={styles.eventHeadingSection}>
              <div className={styles.eventHeadingText}>
                Upcoming Event & Classes
              </div>
              {isAuthenticated &&
              memberAccountInfo.username === memberUserId &&
              memberAccountInfo?.user_authId === user?.sub ? (
                <div
                  className={styles.eventHeadingAddEventButton}
                  onClick={changeEventAddEditModalState}
                >
                  <PlusCircleFilled
                    className={styles.eventHeadingAddEventButtonIcon}
                  />
                  <p className={styles.eventHeadingAddEventButtonText}>
                    Add Event
                  </p>
                </div>
              ) : null}
            </div>
            <div className={styles.eventList}>
              {upcomingEvents?.length ? (
                upcomingEvents
                  ?.filter((i) => i)
                  ?.map((i) => (
                    <Fragment key={i?.id}>
                      <div className={styles.eventListItem} key={i.id}>
                        <div className={styles.eventListItemImageSection}>
                          <img
                            src={
                              imageIsNotInS3(i.image)
                                ? `${i.image}`
                                : `${process.env.REACT_APP_BASE_URL}${i.image}`
                            }
                            className={styles.eventListItemImage}
                            alt="event"
                          />
                          {isAuthenticated &&
                          memberAccountInfo.username === memberUserId &&
                          memberAccountInfo?.user_authId === user?.sub ? (
                            <div
                              className={styles.eventListItemEditButton}
                              onClick={() => {
                                changeEventAddEditModalState();
                                setSelectedEventForEditOrDelete(i);
                              }}
                            >
                              <EditFilled />
                            </div>
                          ) : null}
                          {isAuthenticated &&
                          memberAccountInfo.username === memberUserId &&
                          memberAccountInfo?.user_authId === user?.sub ? (
                            <div
                              className={styles.eventListItemDeleteButton}
                              onClick={() => {
                                changeEventDeleteModalState();
                                setSelectedEventForEditOrDelete(i);
                              }}
                            >
                              <DeleteFilled />
                            </div>
                          ) : null}
                        </div>
                        <div className={styles.eventListItemInfoSection}>
                          <div
                            className={styles.eventListItemInfoSectionTopLine}
                          >
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <p className={styles.eventListItemName}>
                                {i.name}
                              </p>

                              {isAuthenticated &&
                              memberAccountInfo.username === memberUserId &&
                              memberAccountInfo?.user_authId ===
                                user?.sub ? null : (
                                <button
                                  className={styles.registerButton}
                                  onClick={() =>
                                    isAuthenticated &&
                                    memberAccountInfo?.user_authId !== user?.sub
                                      ? registerEventHandler(i)
                                      : loginWithRedirect({
                                          appState: {
                                            returnTo: window.location.pathname,
                                          },
                                        })
                                  }
                                >
                                  {eventInterestData?.length &&
                                  eventInterestData?.find(
                                    (j) =>
                                      Number(j.interest_type_id) ===
                                      Number(i.id)
                                  )
                                    ? "Registered"
                                    : "Register"}
                                </button>
                              )}
                            </div>
                          </div>

                          {/* <a
                          className={styles.eventListItemLink}
                          href={i.link}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <LinkOutlined /> &nbsp; {i.link}
                        </a> */}
                          {i.locationname ? (
                            <p className={styles.eventListItemLocation}>
                              <EnvironmentOutlined /> &nbsp;
                              {i.streetaddress
                                ? `${i.streetaddress},`
                                : ""}{" "}
                              {i.locationname ? `${i.locationname},` : ""}{" "}
                              {i.location}
                            </p>
                          ) : null}
                          <div className={styles.eventListItemTimeText}>
                            <b>Price:</b>{" "}
                            {i.feetype === "free"
                              ? "FREE"
                              : i.feetype === "fixed"
                              ? `${i.feepersession}
                            ${
                              i?.currency
                                ? currencyData.find(
                                    (currency) =>
                                      currency?.currency === i?.currency
                                  )?.currency
                                : ""
                            }`
                              : `${i.slidingscalemin} ${
                                  i?.currency
                                    ? currencyData?.find(
                                        (currency) =>
                                          currency?.currency === i?.currency
                                      )?.currency
                                    : ""
                                } - ${i.slidingscalemax}  ${
                                  i?.currency
                                    ? currencyData?.find(
                                        (currency) =>
                                          currency?.currency === i?.currency
                                      )?.currency
                                    : ""
                                }`}
                          </div>

                          {i?.recurring ? (
                            <p className={styles.eventListItemTimeText}>
                              <b>Recurring:</b>{" "}
                              {i.recurring === "y" ? "Yes" : "No"}
                            </p>
                          ) : null}

                          {i?.recurring === "n" ? (
                            <>
                              <p className={styles.eventListItemTimeText}>
                                <b>Start Time:</b>{" "}
                                {dayjs(i.startdatetime).format("LLLL")}
                              </p>
                              <p className={styles.eventListItemTimeText}>
                                <b>End Time:</b>{" "}
                                {dayjs(i.enddatetime).format("LLLL")}
                              </p>
                            </>
                          ) : (
                            <>
                              {i?.recurringData?.length
                                ? i?.recurringData?.map((recData) => (
                                    <p
                                      key={recData?.id}
                                      className={styles.eventListItemTimeText}
                                    >
                                      {dayjs(recData.startdatetime).format(
                                        "LLL"
                                      )}{" "}
                                      -{" "}
                                      {dayjs(recData.enddatetime).format("LLL")}
                                    </p>
                                  ))
                                : null}
                            </>
                          )}

                          <div
                            className={
                              styles.eventListItemInfoSectionDescription
                            }
                          >
                            {i.description}
                          </div>
                          <div
                            className={
                              styles.eventListItemInfoSectionWellnessKeywords
                            }
                          >
                            {i.wellnessKeywords.length
                              ? i.wellnessKeywords
                                  ?.filter((i) => i)
                                  .map((i) => (
                                    <div
                                      key={i.id}
                                      className={
                                        styles.eventListItemInfoSectionWellnessKeywordItem
                                      }
                                    >
                                      {i.name}
                                    </div>
                                  ))
                              : null}
                          </div>
                        </div>
                      </div>
                      <Divider />
                    </Fragment>
                  ))
              ) : (
                <Empty style={{ marginTop: "100px" }} />
              )}
            </div>
          </Spin>
        </Panel>
      </Collapse>

      {isAuthenticated &&
      memberAccountInfo.username === memberUserId &&
      memberAccountInfo?.user_authId === user?.sub ? (
        <Collapse
          style={{ width: "100%", margin: "24px 0" }}
          expandIconPosition={"end"}
          size="large"
        >
          <Panel header="Previous Events and Classes" key="1">
            <div className={styles.eventList}>
              {previousEvents?.length ? (
                previousEvents
                  ?.filter((i) => i)
                  ?.map((i) => (
                    <Fragment key={i.id}>
                      <div className={styles.eventListItem} key={i.id}>
                        <div className={styles.eventListItemImageSection}>
                          <img
                            src={
                              imageIsNotInS3(i.image)
                                ? `${i.image}`
                                : `${process.env.REACT_APP_BASE_URL}${i.image}`
                            }
                            className={styles.eventListItemImage}
                            alt="event "
                          />

                          {isAuthenticated &&
                          memberAccountInfo.username === memberUserId &&
                          memberAccountInfo?.user_authId === user?.sub ? (
                            <div
                              className={styles.eventListItemEditButton}
                              onClick={() => {
                                changeEventAddEditModalState();
                                setSelectedEventForEditOrDelete(i);
                              }}
                            >
                              <EditFilled />
                            </div>
                          ) : null}
                          {isAuthenticated &&
                          memberAccountInfo.username === memberUserId &&
                          memberAccountInfo?.user_authId === user?.sub ? (
                            <div
                              className={styles.eventListItemDeleteButton}
                              onClick={() => {
                                changeEventDeleteModalState();
                                setSelectedEventForEditOrDelete(i);
                              }}
                            >
                              <DeleteFilled />
                            </div>
                          ) : null}
                        </div>
                        <div className={styles.eventListItemInfoSection}>
                          <div
                            className={styles.eventListItemInfoSectionTopLine}
                          >
                            <p className={styles.eventListItemName}>{i.name}</p>
                          </div>
                          {i.link ? (
                            <a
                              className={styles.eventListItemLink}
                              href={i.link}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <LinkOutlined /> &nbsp; {i.link}
                            </a>
                          ) : null}
                          {i.locationname ? (
                            <p className={styles.eventListItemLocation}>
                              <EnvironmentOutlined /> &nbsp;
                              {i.streetaddress
                                ? `${i.streetaddress},`
                                : ""}{" "}
                              {i.locationname ? `${i.locationname},` : ""}{" "}
                              {i.location}
                            </p>
                          ) : null}
                          <div className={styles.eventListItemTimeText}>
                            <b>Price:</b>{" "}
                            {i.feetype === "free"
                              ? "FREE"
                              : i.feetype === "fixed"
                              ? `${i.feepersession}
                            ${
                              i?.currency
                                ? currencyData.find(
                                    (currency) =>
                                      currency?.currency === i?.currency
                                  )?.currency
                                : ""
                            }`
                              : `${i.slidingscalemin} ${
                                  i?.currency
                                    ? currencyData?.find(
                                        (currency) =>
                                          currency?.currency === i?.currency
                                      )?.currency
                                    : ""
                                } - ${i.slidingscalemax}  ${
                                  i?.currency
                                    ? currencyData?.find(
                                        (currency) =>
                                          currency?.currency === i?.currency
                                      )?.currency
                                    : ""
                                }`}
                          </div>

                          {i?.recurring ? (
                            <p className={styles.eventListItemTimeText}>
                              <b>Recurring:</b>{" "}
                              {i.recurring === "y" ? "Yes" : "No"}
                            </p>
                          ) : null}

                          {i?.recurring === "n" ? (
                            <>
                              <p className={styles.eventListItemTimeText}>
                                <b>Start Time:</b>{" "}
                                {dayjs(i.startdatetime).format("LLLL")}
                              </p>
                              <p className={styles.eventListItemTimeText}>
                                <b>End Time:</b>{" "}
                                {dayjs(i.enddatetime).format("LLLL")}
                              </p>
                            </>
                          ) : (
                            <>
                              {i?.recurringData?.length
                                ? i?.recurringData?.map((recData) => (
                                    <p
                                      key={recData?.id}
                                      className={styles.eventListItemTimeText}
                                    >
                                      {dayjs(recData.startdatetime).format(
                                        "LLL"
                                      )}{" "}
                                      -{" "}
                                      {dayjs(recData.enddatetime).format("LLL")}
                                    </p>
                                  ))
                                : null}
                            </>
                          )}

                          <div
                            className={
                              styles.eventListItemInfoSectionDescription
                            }
                          >
                            {i.description}
                          </div>
                          <div
                            className={
                              styles.eventListItemInfoSectionWellnessKeywords
                            }
                          >
                            {i.wellnessKeywords.length
                              ? i.wellnessKeywords
                                  ?.filter((i) => i)
                                  .map((i) => (
                                    <div
                                      key={i.id}
                                      className={
                                        styles.eventListItemInfoSectionWellnessKeywordItem
                                      }
                                    >
                                      {i.name}
                                    </div>
                                  ))
                              : null}
                          </div>
                        </div>
                      </div>
                      <Divider />
                    </Fragment>
                  ))
              ) : (
                <Empty style={{ marginTop: "100px" }} />
              )}
            </div>
          </Panel>
        </Collapse>
      ) : null}

      {eventAddEditModalState && (
        <MemberEventAddEditForm
          editData={selectedEventForEditOrDelete}
          isOpen={eventAddEditModalState}
          onClose={() => {
            changeEventAddEditModalState();
            setSelectedEventForEditOrDelete();
            getMemberEventsHelper();
          }}
        />
      )}

      {eventDeleteModalState && (
        <DeleteConformationModal
          title={"Delete Event"}
          isOpen={eventDeleteModalState}
          onClose={changeEventDeleteModalState}
          content={
            "Are you sure you want to delete this event. once deleted it can not be recovered"
          }
          onConform={deleteMemberEventHelper}
        />
      )}
    </div>
  );
};

export default MemberEventsInfo;